import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvaliacaoForm = _resolveComponent("AvaliacaoForm")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.store.getters.userPerfil != 'professor')
      ? (_openBlock(), _createBlock(_component_AvaliacaoForm, {
          key: 0,
          onUpdate: _ctx.list,
          avaliacoes: _ctx.rows,
          ref: "modalForm"
        }, null, 8, ["onUpdate", "avaliacoes"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_table, {
        title: "Avaliações",
        "row-key": "id",
        rows: _ctx.rows,
        columns: _ctx.columns,
        filter: _ctx.filter,
        loading: _ctx.loading,
        pagination: { rowsPerPage: 10 }
      }, {
        top: _withCtx(() => [
          _createVNode(_component_q_space),
          _createVNode(_component_q_input, {
            dense: "",
            debounce: "300",
            color: "primary",
            modelValue: _ctx.filter,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "search" })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (_ctx.store.getters.userPerfil != 'professor')
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    "no-caps": "",
                    flat: "",
                    dense: "",
                    color: "primary",
                    "icon-right": "edit",
                    onClick: ($event: any) => (_ctx.openModalForm(props.row))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Editar ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                dense: "",
                color: "green",
                "icon-right": "post_add",
                to: '/enviar-nota/' + props.row.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Enviar Nota ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "filter", "loading"])
    ])
  ]))
}